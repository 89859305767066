// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    dg: 500,  // for dialog
    sm: 600,
    dp: 800,  // for dialog
    md: 900,
    lg: 1200,
    xl: 1440,
    laptop: 1160,
  }
};

export default breakpoints;
